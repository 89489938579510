import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import React, { Fragment } from "react";
import logo from "../logo.svg";
import clinic from "../../../images/clinic.svg";
import cptsEss from "../../../images/cptsEss.svg";
import gp from "../../../images/gp.svg";
import hospital from "../../../images/hospital.svg";
import otherProfessional from "../../../images/otherProfessional.svg";
import specialist from "../../../images/specialist.svg";
import { classNames, SmartLink } from "../../../utils";
import { LocaleSelector, MobileLocaleSelector } from "../LocaleSelector";
import { LANGUAGE_TO_HOMEPAGE, LANG_FR_FR } from "../../../constants";
import { t } from "../../../translations";

const locale = LANG_FR_FR;

const individuals = [
  {
    name: "Médecins spécialistes",
    description:
      "Créez un canal de communication efficace et valorisé avec vos correspondants.",
    href: "/medecins-specialistes",
    icon: specialist,
  },
  {
    name: "Médecins généralistes",
    description:
      "Découvrez et sollicitez facilement les médecins et organisations accessibles sur votre territoire.",
    href: "/medecins-generalistes",
    icon: gp,
  },
  {
    name: "Autres professionnels de santé",
    description:
      "La téléexpertise et Omnidoc sont désormais ouverts à tous les professionnels de santé.",
    href: "/autres-professionnels-de-sante",
    icon: otherProfessional,
  },
];
const groups = [
  {
    name: "Hôpitaux publics et ESPIC",
    description:
      "Sécurisez et valorisez les avis informels. Améliorez le lien avec la ville.",
    href: "/hopitaux",
    icon: hospital,
  },
  {
    name: "CPTS et ESS",
    description:
      "Renforcez les liens entre premier et second recours sur votre territoire.",
    href: "/cpts-ess",
    icon: cptsEss,
  },
  {
    name: "Cliniques et cabinets de groupe",
    description:
      "Centralisez et valorisez les sollicitations de vos correspondants.",
    href: "/cliniques-et-cabinets-de-groupe",
    icon: clinic,
  },
];
const resources = [
  {
    name: "FAQ",
    description: "",
    href: "/info",
  },
  {
    name: "Presse",
    description: "",
    href: "/presse",
  },
  {
    name: "On recrute !",
    description: "",
    href: "https://www.welcometothejungle.com/fr/companies/omnidoc",
  },
];

export const Navigation = (
  <Popover className="z-50 w-full top-0">
    <div className="flex justify-between items-center px-4 py-3 sm:px-6 lg:justify-start lg:space-x-10 min-h-[62px]">
      <div>
        <Link to={LANGUAGE_TO_HOMEPAGE[locale] || "/"} className="flex">
          <span className="sr-only">Omnidoc</span>
          <img className="h-4 w-auto sm:h-4 mb-0" src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="-mr-2 -my-2 lg:hidden">
        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-action-500">
          <span className="sr-only">{t(locale, "navigation.open-menu")}</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>
      </div>
      <div className="hidden lg:flex-1 lg:flex lg:items-center lg:justify-between">
        <Popover.Group as="nav" className="flex grow justify-between max-w-xl">
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group py-1 bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action-500",
                  )}
                >
                  <span>Vous êtes …</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-gray-600" : "text-gray-400",
                      "ml-1 h-5 w-5 group-hover:text-gray-500",
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid lg:grid-cols-2">
                        <div className="relative grid gap-6 bg-white px-5 py-5 sm:gap-8 sm:p-8">
                          <p className="text-sm font-semibold uppercase text-action-600 tracking-wider">
                            Organisations
                          </p>
                          {groups.map(item => (
                            <Link key={item.name} to={item.href}>
                              <Popover.Button className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 text-left">
                                <img
                                  className="flex-shrink-0 h-6 w-6 text-action-600"
                                  aria-hidden="true"
                                  src={item.icon}
                                  alt={item.name}
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Popover.Button>
                            </Link>
                          ))}
                        </div>
                        <div className="relative grid gap-6 bg-white px-5 py-5 sm:gap-8 sm:p-8">
                          <p className="text-sm font-semibold uppercase text-action-600 tracking-wider">
                            Professionnels de santé
                          </p>
                          {individuals.map(item => (
                            <Link key={item.name} to={item.href}>
                              <Popover.Button className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 text-left">
                                <img
                                  className="flex-shrink-0 h-6 w-6 text-action-600"
                                  aria-hidden="true"
                                  src={item.icon}
                                  alt={item.name}
                                />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Popover.Button>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          <div className="items-center">
            <Link
              to="/actualites"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Actualités
            </Link>
          </div>
          <div className="items-center">
            <Link
              to="/interoperabilite"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Interopérabilité
            </Link>
          </div>
          <div className="items-center">
            <Link
              to="/tarifs"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Tarifs
            </Link>
          </div>

          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  className={classNames(
                    open ? "text-gray-900" : "text-gray-500",
                    "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action-500",
                  )}
                >
                  <span>Plus</span>
                  <ChevronDownIcon
                    className={classNames(
                      open ? "text-gray-600" : "text-gray-400",
                      "ml-1 h-5 w-5 group-hover:text-gray-500",
                    )}
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {resources.map(item => (
                          <SmartLink key={item.name} href={item.href}>
                            <div className="-m-3 p-3 block rounded-md hover:bg-gray-50">
                              <p className="text-base font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {item.description}
                              </p>
                            </div>
                          </SmartLink>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </Popover.Group>
        <div className="flex items-center lg:ml-12">
          <Link
            to="/demander-une-demo"
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Demander une démo
          </Link>
          <a
            href="https://app.omnidoc.fr/login"
            className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-action-600 hover:bg-action-700"
          >
            Se&nbsp;connecter
          </a>
          <LocaleSelector locale={locale} />
        </div>
      </div>
    </div>

    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img className="h-5 w-auto sm:h-5 mb-0" src={logo} alt="Logo" />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-action-500">
                  <span className="sr-only">
                    {t(locale, "navigation.close-menu")}
                  </span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-6">
                <p className="text-base font-semibold uppercase text-action-600 tracking-wider">
                  Organisations
                </p>
                {groups.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                  >
                    <img
                      className="flex-shrink-0 h-6 w-6 text-action-600"
                      aria-hidden="true"
                      src={item.icon}
                      alt={item.name}
                    />
                    <div className="ml-4 text-base font-medium text-gray-900">
                      {item.name}
                    </div>
                  </Link>
                ))}
                <p className="text-base font-semibold uppercase text-action-600 tracking-wider">
                  Professionnels de santé
                </p>
                {individuals.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                  >
                    <img
                      className="flex-shrink-0 h-6 w-6 text-action-600"
                      aria-hidden="true"
                      src={item.icon}
                      alt={item.name}
                    />
                    <div className="ml-4 text-base font-medium text-gray-900">
                      {item.name}
                    </div>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
          <div className="py-6 px-5">
            <div className="grid grid-cols-2 gap-4">
              <Link
                to="/actualites"
                className="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Actualités
              </Link>

              <Link
                to="/interoperabilite"
                className="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Interopérabilité
              </Link>

              <Link
                to="/tarifs"
                className="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Tarifs
              </Link>

              {resources.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="mt-6">
              <a
                href="https://app.omnidoc.fr/login"
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-action-600 hover:bg-action-700"
              >
                Se&nbsp;connecter
              </a>
              <p className="mt-6 text-center text-base font-medium text-gray-500">
                Vous souhaitez découvrir nos solutions ?{" "}
                <Link
                  to="/demander-une-demo"
                  className="text-action-600 hover:text-action-500"
                >
                  Demander une démo
                </Link>
              </p>
            </div>
            <MobileLocaleSelector locale={locale} />
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
);
