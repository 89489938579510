import React, { useEffect, useState } from "react";
import * as navigation_fr from "./fr-FR/navigation";
import * as navigation_frbe from "./fr-BE/navigation";
import * as navigation_de from "./de-DE/navigation";
import { LANG_DE_DE, LANG_FR_BE, LANG_FR_FR } from "../../constants";

const LOCALE_NAV = {
  [LANG_FR_FR]: navigation_fr.Navigation,
  [LANG_FR_BE]: navigation_frbe.Navigation,
  [LANG_DE_DE]: navigation_de.Navigation,
};

/**
 * The top Header, locale dependant. Defaults to french
 */
export const Header = ({ locale = LANG_FR_FR }) => {
  const SCROLL_THRESH = 10;
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => setIsScrolled(window.scrollY > SCROLL_THRESH);
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <div className="h-3"></div>
      <div
        className={`w-full ${
          isScrolled ? " sticky top-0 z-50 shadow-sm bg-white" : ""
        }`}
      >
        {LOCALE_NAV[locale]}
      </div>
    </>
  );
};
