import { LANG_EN, LANG_FR_FR, LANG_FR_BE, LANG_DE_DE } from "./constants";

export const t = (locale, key) =>
  translations[locale]?.[key] || translations[LANG_EN]?.[key] || key;

export const translator = locale => key => t(locale, key);

export const Trans = ({ locale, key }) => t(locale, key);

const translations = {
  [LANG_EN]: {
    "seo.title": "eConsult Platform",
    "seo.description": "Omnidoc is an eConsult Platform",
    "navigation.open-menu": "Open menu",
    "navigation.close-menu": "Close menu",
    "news.title": "News",
    "news.seo-title": "News",
    "press.title": "Press",
    "press.download-kit": "Download our press kit",
    "press.kit": "Press kit",
    "info.title": "Information",
    "info.seo-title": "Information",
  },
  [LANG_FR_FR]: {
    "seo.title": "Solution de téléexpertise et de e-RCP",
    "seo.description":
      "Omnidoc est une solution de téléexpertise et de e-RCP à destination des professionnels de santé et des organisations médicales qui souhaitent améliorer, sécuriser et valoriser les avis échangés.",
    "navigation.open-menu": "Ouvrir le menu",
    "navigation.close-menu": "Fermer le menu",
    "news.title": "Actualités",
    "news.seo-title": "Actualités - Téléexpertise",
    "press.title": "Presse",
    "press.download-kit": "Télécharger notre dossier de presse",
    "press.kit": "Kit presse",
    "info.title": "Informations",
    "info.seo-title": "Toutes les informations - Téléexpertise",
  },
  [LANG_DE_DE]: {
    "seo.title": "Omnidoc, Telekonsil Plattform",
    "seo.description":
      "Omnidoc ist eine Telekonsil Plattform für Gesundheitsfachkräfte und medizinische Einrichtungen, die den Austausch über den Befund eines Patientenfalls verbessern, sichern und effizienter gestalten möchte.",
    "news.title": "Aktuelles",
    "news.seo-title": "Aktuelles",
  },
};

translations[LANG_FR_BE] = translations[LANG_FR_FR];
