import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import { classNames } from "../../utils";
import {
  LANG_FR_FR,
  LANG_FR_BE,
  LANG_DE_DE,
  LANGUAGE_TO_HOMEPAGE,
} from "../../constants";

const LOCALE_ITEMS = {
  [LANG_FR_FR]: {
    flag: "🇫🇷",
    name: "France",
  },
  [LANG_FR_BE]: {
    flag: "🇧🇪",
    name: "Belgique",
    language: "Français",
  },
  [LANG_DE_DE]: {
    flag: "🇩🇪",
    name: "Deutschland",
  },
};

export const LocaleSelector = ({ locale }) => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open ? "text-gray-900" : "text-gray-500",
            "ml-8 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-action-500",
          )}
        >
          <span aria-label={LOCALE_ITEMS[locale]?.name}>
            {LOCALE_ITEMS[locale]?.flag}
          </span>
          <ChevronDownIcon
            className={classNames(
              open ? "text-gray-600" : "text-gray-400",
              "ml-1 h-5 w-5 group-hover:text-gray-500",
            )}
            aria-hidden="true"
          />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 transform -translate-x-2/3 mt-3 px-2 w-screen max-w-xs sm:px-0">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                {Object.entries(LOCALE_ITEMS).map(([locale, item]) => (
                  <Link key={locale} to={LANGUAGE_TO_HOMEPAGE[locale]}>
                    <div className="-m-3 p-3 block rounded-md hover:bg-gray-50">
                      <p className="text-base font-medium text-gray-900">
                        <span
                          role="img"
                          aria-label={item.name}
                          className="mr-2"
                        >
                          {item.flag}
                        </span>
                        {item.name}
                        {item.language && (
                          <span className="ml-2 text-sm text-gray-500">
                            {item.language}
                          </span>
                        )}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

export const MobileLocaleSelector = ({ locale }) => (
  <div className="mt-6 py-6 px-5">
    <div className="grid grid-cols-1 sm:grid-cols-2 md:sm:grid-cols-3 gap-4">
      {Object.entries(LOCALE_ITEMS).map(([locale, item]) => (
        <Link
          key={locale}
          to={LANGUAGE_TO_HOMEPAGE[locale]}
          className="text-base font-medium text-gray-900 hover:text-gray-700"
        >
          <span role="img" aria-label={item.name} className="mr-2">
            {item.flag}
          </span>
          {item.name}
          {item.language && (
            <span className="ml-2 text-sm text-gray-500">{item.language}</span>
          )}
        </Link>
      ))}
    </div>
  </div>
);
